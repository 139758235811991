<script>
import Login from '@/model/Login.js';

export default {
  data() {
    return {
      logged: (this.$store.state && this.$store.state.logged) || false,
      singleSignOn: this.$store.state.sso || {},
      isSingleSignOnUser: false
    };
  },
  // apply render method to be Renderless Component
  render() {
    return this.$createElement("template");
  },
  created() {
    if (Object.keys(this.singleSignOn).length > 0 && (typeof this.singleSignOn.isSyncData === "boolean" || (this.singleSignOn.singleSignOnList && this.singleSignOn.singleSignOnList.length > 0))) {
      this.isSingleSignOnUser = true;
    }

    if (!this.isSingleSignOnUser) {
      this.$router.push({ path: "/" });
    } else if (this.isSingleSignOnUser && this.logged && this.$store.state.accessToken) {
      this.singleSignOut();
    } else {
      // clear Cached Vuex
      this.$store.commit('logout');
      this.$router.push({ name: "Login" }).catch(() => {
        // do nothing
      });
    }
  },
  methods: {
    singleSignOut() {
      const singleSignOn = this.singleSignOn;
      const redirectUrl = singleSignOn.redirectUrl;
      const user = this.$store.state.user;
      if (redirectUrl && redirectUrl !== "") {
        // call API logout
        Login.singleSignOut(user.jtiToken, singleSignOn).catch((error) => console.log(error));
        // clear Cached Vuex
        this.$store.commit('logout');
        setTimeout(() => {
          // redirect to First Own SSO
          window.location.replace(redirectUrl);
        }, 100);
      } else {
        // call API logout
        Login.singleSignOut(user.jtiToken, singleSignOn).then((redirectUrlResponse) => {
          // clear Cached Vuex
          this.$store.commit('logout');
          if (redirectUrlResponse.data && redirectUrlResponse.data !== "") {
            // redirect to First Own SSO
            window.location.replace(redirectUrlResponse.data);
          } else {
            // redirect to Self Login page
            this.$router.push({ name: "Login" });
          }
        }).catch((error) => console.log(error));
      }
    }
  }
};
</script>

<style scoped>
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  opacity: 1 !important;
}
</style>
